var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew6-white-sheet",
    {
      staticClass: "mew-component--features-swap pa-6 pa-md-10",
      attrs: { "max-width": "700px" },
    },
    [
      _c("div", { staticClass: "mew-heading-1 mb-3" }, [
        _vm._v(_vm._s(_vm.$t("home.features.swap.heading"))),
      ]),
      _c("div", [
        _vm._v(" " + _vm._s(_vm.$t("home.features.swap.title")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "mt-10" },
        [
          !_vm.loading && !_vm.error
            ? _c(
                "v-row",
                _vm._l(_vm.swapData, function (data, key) {
                  return _c(
                    "v-col",
                    {
                      key: key,
                      staticStyle: { padding: "4px !important" },
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      data.rate
                        ? _c(
                            "v-sheet",
                            {
                              staticClass:
                                "d-flex align-center justify-space-between border-radius--5px py-5 px-4 cursor cursor--pointer",
                              attrs: { color: "tableHeader" },
                              on: {
                                click: function ($event) {
                                  return _vm.goToSwap(data)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text-uppercase" }, [
                                _vm._v(
                                  " 1 " +
                                    _vm._s(data.fromT.symbol) +
                                    " / " +
                                    _vm._s(data.rate) +
                                    " " +
                                    _vm._s(data.toT.symbol) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("img", {
                                    attrs: {
                                      width: "22",
                                      src: require("@/assets/images/currencies/" +
                                        data.fromT.symbol.toLowerCase() +
                                        ".png"),
                                      alt: "currency-icon",
                                    },
                                  }),
                                  _c("img", {
                                    staticClass: "mx-2",
                                    attrs: {
                                      width: "18",
                                      src: require("@/assets/images/icons/icon-swap-arrow-grey.png"),
                                      alt: "swap-icon",
                                    },
                                  }),
                                  _c("img", {
                                    attrs: {
                                      width: "22",
                                      src: require("@/assets/images/currencies/" +
                                        data.toT.symbol.toLowerCase() +
                                        ".png"),
                                      alt: "eth-icon",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c("mew-button", {
            staticClass: "mx-auto mt-12 d-block",
            attrs: { title: "Swap", "btn-size": "xlarge" },
            nativeOn: {
              click: function ($event) {
                return _vm.$router.push({
                  name: _vm.ROUTES_HOME.ACCESS_WALLET.NAME,
                  params: {},
                })
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }